import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routesPc = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/home/index.vue'),
  },
  {
    path: '/pool',
    name: 'pool',
    component: () => import('@/views/home/pool.vue'),
  }
]


let routerYd 
if (process.env.VUE_APP_MODEL == 'earn') {
  routerYd = [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/movem/link.vue'),
    }
  ]
} else if (process.env.VUE_APP_MODEL == 'airdrop') {
  routerYd = [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/movem/airdrop.vue'),
    }
  ]
} else {
  routerYd = [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/movem/index.vue'),
    },
    {
      path: '/pool',
      name: 'pool',
      component: () => import('@/views/movem/pool.vue'),
    },
    {
      path: '/earn',
      name: 'earn',
      component: () => import('@/views/movem/link.vue'),
    },
    {
      path: '/airdrop',
      name: 'airdrop',
      component: () => import('@/views/movem/airdrop.vue'),
    }
  ]
}


var routes = []
let Adaptive = /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
if (window.Telegram) {
  routes = routerYd
} else if (navigator.userAgent.match(Adaptive)) {
  routes = routerYd
} else {
  routes = routesPc
}

const router = new VueRouter({
  mode: 'hash',
  routes
})

export default router
