import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

import en from './langs/en.json';
import zh from './langs/zh.json';
import vi from './langs/vi.json';

const messages = {
  en,
  zh,
  vi
};

const i18n = new VueI18n({
  locale: 'en', // 设置默认语言
  fallbackLocale: 'en', // 回退语言
  messages,
});

export default i18n;
